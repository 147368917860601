import React from "react";
import SurveyForm from "../Components/SurveyForm";
import MainLayout from "../Layout/MainLayout";
import { Col, Typography } from "antd";

const { Title, Paragraph } = Typography;

const SurveyPage = () => {
  return (
    <MainLayout>
      <Col>
        <Col className="container">
          <Col style={{ maxWidth: "800px", margin: "auto" }}>
            <Title level={2}>
              Please give us your feedback about an app to lock your children's
              phones
            </Title>
            <Title level={3} className="text-secondary">
              The problem and our proposed solution (to be available by 15th Feb
              2025 on Apple and Google store)
            </Title>
            <Paragraph>
              In response to the impact technology and more particularly, mobile
              phones are having on children, we are developing an app which will
              allow you to lock your children's phones away before, during or
              after school. The app will allow you to configure how you want to
              lock your children's phones - from basic to advanced locking. You
              can still contact them through specially allowed 3 numbers but
              they can only use the phone if you allow ad-hoc one time access.
            </Paragraph>
            <Paragraph>
              We are reaching out to parents and schools to co-design the best
              solution.
            </Paragraph>

            <SurveyForm />
          </Col>
        </Col>
      </Col>
    </MainLayout>
  );
};

export default SurveyPage;
