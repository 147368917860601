import React, { useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  Button,
  Row,
  Col,
  Modal,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../Config/Axios";
import { student_tours_path } from "../Config/Config";

const { Title } = Typography;

const SurveyForm: React.FC = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [otherSelected, setOtherSelected] = useState({
    identity: false,
    location: false,
    issues: false,
    features: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (question: any, checkedValues: any) => {
    setOtherSelected((prev) => ({
      ...prev,
      [question]: checkedValues.includes("Other"),
    }));

    if (!checkedValues.includes("Other")) {
      form.resetFields([`${question}Other`]);
    }
  };

  const navigate = useNavigate();

  const handleModalClose = () => {
    setIsModalVisible(false);
    form.resetFields();
    navigate("/");
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const processedValues: any = { ...values };

    // List of questions that have "Other" options
    const questionsWithOther = Object.keys(otherSelected);

    questionsWithOther.forEach((question) => {
      if (otherSelected[question as keyof typeof otherSelected]) {
        const otherInput = values[`${question}Other`];
        if (otherInput) {
          // Replace "Other" with "Other - specified text"
          processedValues[question] = processedValues[question].map(
            (item: string) =>
              item === "Other" ? `Other - ${otherInput}` : item
          );
        }
        // Remove the separate "Other" input field from the final values
        delete processedValues[`${question}Other`];
      }
    });

    try {
      // Replace with your actual API endpoint
      await AxiosInstance.post(`/api/survey/save`, processedValues);
      setIsLoading(false);
      form.resetFields();
      setIsModalVisible(true);

      // Auto-close the modal after 3 seconds
      setTimeout(() => {
        setIsModalVisible(false);
        window.location.href = student_tours_path;
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      console.error("Form submission error:", error);
      // Handle error appropriately in real application
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: "600px" }}
      className="survey-form"
      scrollToFirstError={
        { behavior: "smooth", block: "end", focus: true } as any
      }
      requiredMark={false} // This hides the required asterisks
    >
      <Form.Item
        label="1. Name"
        name="name"
        rules={[{ required: true, message: "Please enter your name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="2. Email"
        name="email"
        rules={[
          { required: true, message: "Please enter your email" },
          { type: "email", message: "Please enter a valid email" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="3. How do you identify?"
        name="identity"
        rules={[{ required: true, message: "Please select an option" }]}
      >
        <Checkbox.Group
          onChange={(checkedValues) =>
            handleCheckboxChange("identity", checkedValues)
          }
        >
          <Row>
            <Col span={24}>
              <Checkbox value="Parent of multiple children">
                Parent of multiple children
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Parent of single children">
                Parent of single children
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Single parent of multiple children">
                Single parent of multiple children
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Single parent of single children">
                Single parent of single children
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="School teacher">School teacher</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="School principle">School principle</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Other">Other (please specify)</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      {otherSelected.identity && (
        <Form.Item
          name="identityOther"
          rules={[{ required: true, message: "Please specify your answer." }]}
        >
          <Input.TextArea placeholder="Specify Other" />
        </Form.Item>
      )}

      <Form.Item
        label="4. Location"
        name="location"
        rules={[{ required: true, message: "Please select your location" }]}
      >
        <Checkbox.Group
          onChange={(checkedValues) =>
            handleCheckboxChange("location", checkedValues)
          }
        >
          <Row>
            <Col span={24}>
              <Checkbox value="Asia">Asia</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Australia">Australia</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="US">US</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Europe">Europe</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Other">Other (please specify)</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      {otherSelected.location && (
        <Form.Item
          name="locationOther"
          rules={[{ required: true, message: "Please specify your location." }]}
        >
          <Input.TextArea placeholder="Specify Other" />
        </Form.Item>
      )}

      <Form.Item
        label="5. Do you have children in the below age group?"
        name="ageGroup"
        rules={[{ required: true, message: "Please select an option" }]}
      >
        <Checkbox.Group>
          <Row>
            <Col span={24}>
              <Checkbox value="Less than 11">Less than 11</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Between 11 and 13">Between 11 and 13</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Between 13 and 14">Between 13 and 14</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Between 14 and 16">Between 14 and 16</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Between 16 and 17">Between 16 and 17</Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="More than 17">More than 17</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        label="6. What are the biggest issues you face regarding your children's phone usage?"
        name="issues"
        rules={[{ required: true, message: "Please select an option" }]}
      >
        <Checkbox.Group
          onChange={(checkedValues) =>
            handleCheckboxChange("issues", checkedValues)
          }
        >
          <Row>
            <Col span={24}>
              <Checkbox value="Too much usage during school - stops them from focusing on school work">
                Too much usage during school - stops them from focusing on
                school work
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Too much usage at home and weekends">
                Too much usage at home and weekends
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="They are losing focus and finding it hard to concentrate">
                They are losing focus and finding it hard to concentrate
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="They are exposed to risks of cyber bullying">
                They are exposed to risks of cyber bullying
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Their behavior is deteriorating">
                Their behavior is deteriorating
              </Checkbox>
            </Col>

            <Col span={24}>
              <Checkbox value="Other">Other (please specify)</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      {otherSelected.issues && (
        <Form.Item
          name="issuesOther"
          rules={[{ required: true, message: "Please specify the issue." }]}
        >
          <Input.TextArea placeholder="Specify Other" />
        </Form.Item>
      )}

      <Form.Item
        label="7. If you had an app that could lock your children's phones, what features would you like?"
        name="features"
        rules={[{ required: true, message: "Please select an option" }]}
      >
        <Checkbox.Group
          onChange={(checkedValues) =>
            handleCheckboxChange("features", checkedValues)
          }
        >
          <Row>
            <Col span={24}>
              <Checkbox
                value="Students can only dial and receive calls from 3 pre-determined
                phone numbers"
              >
                Students can only dial and receive calls from 3 pre-determined
                phone numbers
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox
                value="If students need access to the phone, they can ask for 1-time access and you can provide it for limited time
"
              >
                If students need access to the phone, they can ask for 1-time
                access and you can provide it for limited time
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Parents can either manually decide daily or set up pre-set times to lock the phone (based on a calendar)">
                Parents can either manually decide daily or set up pre-set times
                to lock the phone (based on a calendar)
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="If this feature was available, would you use outside school hours?">
                If this feature was available, would you use outside school
                hours?
              </Checkbox>
            </Col>

            <Col span={24}>
              <Checkbox value="In case the app was disabled or deleted, the parent would come to know">
                In case the app was disabled or deleted, the parent would come
                to know
              </Checkbox>
            </Col>

            <Col span={24}>
              <Checkbox value="Other">Other (please specify)</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      {otherSelected.features && (
        <Form.Item
          name="featuresOther"
          rules={[{ required: true, message: "Please specify the feature." }]}
        >
          <Input.TextArea placeholder="Specify Other" />
        </Form.Item>
      )}

      <Form.Item
        label="8. With regards to the below, would you agree with the below?"
        name="concerns"
        rules={[{ required: true, message: "Please select an option" }]}
      >
        <Checkbox.Group>
          <Row>
            <Col span={24}>
              <Checkbox value="Setting up the time when to lock the phone seems like a time-consuming task">
                Setting up the time when to lock the phone seems like a
                time-consuming task
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Asking/encouraging children to download the app will be futile">
                Asking/encouraging children to download the app will be futile
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Children need to use the phone all the time for schoolwork and so this solution isnt practical">
                Children need to use the phone all the time for schoolwork and
                so this solution isnt practical
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Children need to be able to contact lot of people (more than 3) lot of the time">
                Children need to be able to contact lot of people (more than 3)
                lot of the time
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="I am not worried about what my children does at school">
                I am not worried about what my children does at school
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="  I want my children to have full freedom">
                I want my children to have full freedom
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="I am concerned about any breach of data or privacy">
                I am concerned about any breach of data or privacy
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        label="9. Would you be interested in hearing from us?"
        name="subscription"
        rules={[{ required: true, message: "Please select an option" }]}
      >
        <Checkbox.Group>
          <Row>
            <Col span={24}>
              <Checkbox value="Yes, happy to provide my inputs to fine tune development?">
                Yes, happy to provide my inputs to fine tune development?
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value=" Yes, happy to be the beta testers and provide feedback?">
                Yes, happy to be the beta testers and provide feedback?
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="If it works, would happy to be a paying customer (tentatively less than $3 USD/month)">
                If it works, would happy to be a paying customer (tentatively
                less than $3 USD/month)
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="Not now - contact me after it gets built and others have used it">
                Not now - contact me after it gets built and others have used it
              </Checkbox>
            </Col>

            <Col span={24}>
              <Checkbox value="No contact at all">No contact at all</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          style={{ width: "80%" }}
          size="large"
          icon={isLoading ? <LoadingOutlined /> : null}
          iconPosition="end"
        >
          Submit
        </Button>
      </Form.Item>

      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => handleModalClose()}
        footer={null}
        closable={false}
      >
        <div style={{ textAlign: "center" }}>
          <Title level={3}>Thank you for your response!</Title>
        </div>
      </Modal>
    </Form>
  );
};

export default SurveyForm;
