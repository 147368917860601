import { useNavigate } from "react-router-dom";
import {
  careermap_tool_path,
  mba_tool_path,
  student_tours_path,
} from "../Config/Config";
import { ArrowRightOutlined, CompassFilled } from "@ant-design/icons";
import { Row, Col, Card, Button, Typography } from "antd";
import MainLayout from "../Layout/MainLayout";
import { FaGraduationCap, FaPlaneDeparture } from "react-icons/fa";

const { Title, Paragraph } = Typography;

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div className="pb-0 px-0">
        <section id="intro" className="intro-section ">
          <div className="container p-0">
            <Row justify="center" gutter={[16, 16]}>
              {/* MBA and Study Abroad Section */}
              <Col xs={24} sm={12} lg={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Card
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="icon-container bg-primary">
                      <FaGraduationCap className="icon" />
                    </div>
                    <Title level={3}>MBA and Study Abroad</Title>
                    <Paragraph className="card-description">
                      Tailored support and guidance for your MBA or study abroad
                      journey. From application assistance to settling in, we
                      help you every step of the way.
                    </Paragraph>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => navigate(mba_tool_path)}
                      className="custom-demo-btn"
                      icon={<ArrowRightOutlined />}
                      iconPosition="end"
                      style={{ marginTop: "auto" }} // Push the button to the bottom
                    >
                      Explore now
                    </Button>
                  </Card>
                </div>
              </Col>

              {/* Student Tours Section */}
              <Col xs={24} sm={12} lg={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Card
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="icon-container bg-primary-2">
                      <FaPlaneDeparture className="icon" />
                    </div>
                    <Title level={3}>Student Tour</Title>
                    <Paragraph className="card-description">
                      Experience international leadership programs, cultural
                      immersion, and excursions to shape global perspectives and
                      skills.
                    </Paragraph>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => navigate(student_tours_path)}
                      className="custom-demo-btn"
                      icon={<ArrowRightOutlined />}
                      iconPosition="end"
                      style={{ marginTop: "auto" }} // Push the button to the bottom
                    >
                      Explore now
                    </Button>
                  </Card>
                </div>
              </Col>

              {/* Career Mapping Section */}
              <Col xs={24} sm={12} lg={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Card
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="icon-container bg-primary-3">
                      <CompassFilled className="icon" />
                    </div>
                    <Title level={3}>Career Mapping</Title>
                    <Paragraph className="card-description">
                      Plan your career with insights into industry trends, skill
                      gap analysis, and a customized roadmap for your
                      professional journey.
                    </Paragraph>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => navigate(careermap_tool_path)}
                      className="custom-demo-btn"
                      icon={<ArrowRightOutlined />}
                      iconPosition="end"
                      style={{ marginTop: "auto" }} // Push the button to the bottom
                    >
                      Explore now
                    </Button>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </MainLayout>
  );
};

export default HomePage;
