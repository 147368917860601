import axios from "axios";
import { backendUrl } from "./Config";

const AxiosInstance = axios.create({

  baseURL: backendUrl,

});

export default AxiosInstance;
